import * as d3 from "d3";
import React, { useRef, useEffect } from "react";
import Helmet from "react-helmet";
import "./visualisation.css";
import BlockContent from "../block-content";

function Visualisation(props) {
  const {
    _rawTopText,
    topText,
    _rawBottomText,
    bottomText,
    _rawSideText,
    sideText,
    width,
    height,
    scripts,
    slug,
  } = props;
  const visualisationTopText = _rawTopText
    ? _rawTopText
    : topText
    ? topText
    : [];
  const visualisationBottomText = _rawBottomText
    ? _rawBottomText
    : bottomText
    ? bottomText
    : [];
  const visualisationSideText = _rawSideText
    ? _rawSideText
    : sideText
    ? sideText
    : [];

  var svg;
  const domRef = useRef();

  const createSVG = (domRef) => {
    var d3Container = d3.select(domRef);
    var svg = d3Container
      .append("svg")
      .attr("width", width)
      .attr("height", height);
    return svg;
  };

  var dependentScripts = {};
  scripts
    .filter((script) => script.type === "text/javascript")
    .map((script) => {
      dependentScripts[script.scriptSrc] = script.scriptSrc;
      return;
    });

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const $s = require("scriptjs");
      if (!svg) {
        svg = createSVG(domRef.current);
      }

      $s.ready(
        Object.keys(dependentScripts),
        function() {
          renderVisualisation();
        },
        function(depsNotFound) {
          depsNotFound.forEach(function(dep) {
            $s(dependentScripts[dep], dep);
          });
        }
      );

      return function cleanup() {
        svg.remove();
        svg = null;
      };
    }
  }, [slug, height, width]);

  const renderVisualisation = () => {
    if (svg) {
      const renderFunctionName = "renderVisualisation__" + slug.current;
      console.log("renderVisualisation() calling " + renderFunctionName);
      let renderFunction = window[renderFunctionName];
      if (renderFunction) {
        renderFunction(d3, svg);
      }
    }
  };

  return (
    <div>
      <Helmet>
        {scripts &&
          scripts
            .filter((script) => script.type === "text/css")
            .map((script) => (
              <link rel="stylesheet" type="text/css" href={script.scriptSrc} />
            ))}
      </Helmet>
      <div className="wrapper">
        <div className="top">
          <div>{<BlockContent blocks={visualisationTopText} />}</div>
        </div>
        <div className="main">
          <div className="chart">
            <div id="chartDiv" ref={domRef}></div>
          </div>
          {_rawSideText !== null && (
            <div className="sideText">
              <div>{<BlockContent blocks={visualisationSideText} />}</div>
            </div>
          )}
        </div>
        <div className="bottom">
          <div>{<BlockContent blocks={visualisationBottomText} />}</div>
        </div>
      </div>
    </div>
  );
}

export default Visualisation;
