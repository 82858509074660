import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import ParallaxContainer from "../components/parallax-container";

export const productsAndServicesPageGROQ = `
 *[_id == 'productsServicesSettings' || _id == $id] {
	_id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
	services[]-> {
    _id,
    title,
    subtitle,
    themeColour,
    thumbnail {
    	asset->,
    	alt
  	},
		slug
  },
  products[]-> {
  _id,
  title,
  subtitle,
  themeColour,
  thumbnail {
  	asset->,
  	alt
	},
	slug
}

}
`;

export default function ProductsAndServices(props) {
  const { sanityProductsServicesSettings, serviceNodes, productNodes } = props;
  return (
    <Layout>
      <SEO
        title={sanityProductsServicesSettings.title}
        description={sanityProductsServicesSettings.description}
        keywords={sanityProductsServicesSettings.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div id="productsAndServices">
          <div className="fullWidth">
            <ParallaxContainer
              images={sanityProductsServicesSettings.parallaxImages}
            />
            <div id="homeBanner">
              <span>{sanityProductsServicesSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <div className="center">
              <p>{sanityProductsServicesSettings.description}</p>
            </div>
            <hr />
            <div id="delay">
              <section id="productServices">
                <h2>Services</h2>
                <ul>
                  {serviceNodes.map((service) => (
                    <li id={service.title} key={service.id}>
                      <Link
                        style={{
                          backgroundImage: `url(${imageUrlFor(
                            buildImageObj(service.thumbnail)
                          ).url()})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        to={"/services/" + service.slug.current}
                      >
                        <span className="textContent">
                          <span
                            className="title"
                            style={{ color: service.themeColour.hex }}
                          >
                            {service.title}
                          </span>
                          <br />
                          <span className="shortDescription">
                            {service.subtitle}
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
              <hr className="responsiveShow" />
              <section id="products">
                <h2>Products</h2>
                <ul>
                  {productNodes.map((product) => (
                    <li id={product.title} key={product.id}>
                      <Link
                        style={{
                          backgroundImage: `url(${imageUrlFor(
                            buildImageObj(product.thumbnail)
                          ).url()})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        to={"/products/" + product.slug.current}
                      >
                        <span className="textContent">
                          <span
                            className="title"
                            style={{ color: product.themeColour.hex }}
                          >
                            {product.title}
                          </span>
                          <br />
                          <span className="shortDescription">
                            {product.subtitle}
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
