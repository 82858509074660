import React from "react";
import Layout from "../containers/layout";

export default function Spinner() {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <h1 style={{ fontWeight: "bold" }}>
          Unable to preview content of this type
        </h1>
      </div>
    </Layout>
  );
}
