import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import ParallaxContainer from "../components/parallax-container";

import SingleLab from "../components/SingleLab";

export const labsGROQ = `
*[_id == 'labsSettings' || _id == $id] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  labs[]->{
    _id,
    title,
    subtitle,
    body,
    links[],
    attachments[] {
      label,
      attachment {
        asset->
      }
		}
  }
}`;

export default function LabsPage(props) {
  const { sanityLabsSettings } = props;

  return (
    <Layout>
      <SEO
        title={sanityLabsSettings.title}
        description={sanityLabsSettings.description}
        keywords={sanityLabsSettings.keywords}
      />
      <main id="main" className="homePage site-main">
        <div id="labs">
          <div style={{ overflow: "hidden" }} className="fullWidth">
            <ParallaxContainer images={sanityLabsSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{sanityLabsSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <div className="center">
              <p>{sanityLabsSettings.labsDescription}</p>
              <p />
            </div>
            <hr />
            <section id="labsTab">
              <ul>
                {sanityLabsSettings.labs &&
                  sanityLabsSettings.labs.map((lab, index) => {
                    return <SingleLab key={index} lab={lab} />;
                  })}
              </ul>
            </section>
          </div>
        </div>
      </main>
    </Layout>
  );
}
