import React from "react";
import pdf from "../../static/PDFICON.svg";
import external from "../../static/EXTERNALLINKICON.svg";
import BlockContent from "../components/block-content";

export default function SingleLab(props) {
  const { lab } = props;
  const labBody = lab._rawBody ? lab._rawBody : lab.body ? lab.body : [];
  return (
    <li key={lab.id}>
      <div className="left col1_2">
        <span className="title">{lab.title}</span>
        <p>{lab.subtitle}</p>
      </div>
      <div className="col1_2">
        <BlockContent blocks={labBody} />
        <div style={{ marginTop: 10 }}>
          <ul className="lab-file-attachments">
            {lab.links &&
              lab.links.map((link, index) => (
                <li key={index}>
                  <a href={link.target}>
                    <img
                      alt="external-link-icon"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                      src={external}
                      // data-pagespeed-url-hash={1068397289}
                      // onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                    />
                    <span>{link.label}</span>
                  </a>
                </li>
              ))}
            {lab.attachments &&
              lab.attachments.map((attachment, index) => (
                <li key={index}>
                  <a href={attachment.attachment.asset.url}>
                    <img
                      alt="document-icon"
                      style={{
                        height: "24px",
                        width: "24px",
                      }}
                      src={pdf}
                      // data-pagespeed-url-hash={639732097}
                      // onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                    />
                    <span>{attachment.label}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </li>
  );
}
