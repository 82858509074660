import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes } from "../lib/helpers";
import PodcastPreviewGrid from "../components/podcast/podcast-preview-grid";
import ParallaxContainer from "../components/parallax-container";
import Pagination from "../components/Pagination";
import Archive from "../components/blog/Archive";

export const podcastIndexGROQ = `
*[_id == 'podcastSettings' || _id == $id] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  widgetColour,
  widgetAutoPlay,
  widgetHideRelated,
  widgetShowComments,
  widgetShowReposts,
  widgetShowTeaser,
  widgetBuying,
  widgetSharing,
  widgetDownload,
  widgetShowArtwork,
  widgetShowPlayCount,
  widgetShowUser,
  widgetStartTrack,
  widgetSingleActive,
  publishedAt,
  author->{
    title,
    mainImage {
      asset->
    }
  },
	categories[]->,
	mainImage {
    alt,
    asset->
  },
	slug,
  body,
  "podcasts": *[_type=='podcast' && defined(slug.current)] | order(publishedAt desc) {
    id,
    publishedAt,
    mainImage {asset->},
		title,
		body,
		slug
  }
}`;

export const query = graphql`
  query PodcastPageQuery($skip: Int!, $limit: Int!) {
    sanityPodcastSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
    }
    archivePodcasts: allSanityPodcast(
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          slug {
            current
          }
        }
      }
    }
    podcasts: allSanityPodcast(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`;

const PodcastsIndex = (props) => {
  const { data, errors } = props;
  const currentPage = props.pageContext && props.pageContext.currentPage;
  const numPages = props.pageContext && props.pageContext.numPages;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/podcasts"
      : `/podcasts/${(currentPage - 1).toString()}`;
  const nextPage = `/podcasts/${(currentPage + 1).toString()}`;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const sanityPodcastSettings = (data || {}).sanityPodcastSettings;
  const podcastsNodes = data && data.podcasts && mapEdgesToNodes(data.podcasts);
  const archivePodcasts =
    data && data.archivePodcasts && mapEdgesToNodes(data.archivePodcasts);

  return (
    <Layout>
      <SEO
        title={sanityPodcastSettings.title}
        description={sanityPodcastSettings.description}
        keywords={sanityPodcastSettings.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div className="fullWidth">
            <ParallaxContainer images={sanityPodcastSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{sanityPodcastSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <p className="center">{sanityPodcastSettings.description}</p>
            <div className="index-wrapper">
              <div className="index-list">
                {podcastsNodes && podcastsNodes.length > 0 && (
                  <PodcastPreviewGrid nodes={podcastsNodes} />
                )}
              </div>
              <div className="index-sidebar">
                <Archive
                  title="Podcast Archive"
                  archiveData={archivePodcasts}
                  route="podcast"
                />
              </div>
            </div>
            {currentPage && (
              <Pagination
                isFirst={isFirst}
                prevPage={prevPage}
                currentPage={currentPage}
                numPages={numPages}
                nextPage={nextPage}
                isLast={isLast}
              />
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PodcastsIndex;
