import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getVisualisationUrl } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";

import styles from "../blog/blog-post-preview.module.css";
import { responsiveTitle3 } from "../typography.module.css";

function VisualisationPreview(props) {
  return (
    <Link className={styles.root} to={getVisualisationUrl(props.slug.current)}>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
    </Link>
  );
}

export default VisualisationPreview;
