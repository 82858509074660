import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import ParallaxContainer from "../components/parallax-container";
import useWindowWidth from "../hooks/useWindowWidth";

export const homePageGROQ = `
 *[_id == 'siteSettings' || _id == $id] {
	_id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
	services[]-> {
    _id,
    title,
    subtitle,
    themeColour,
    thumbnail {
    	asset->,
    	alt
  	},
		slug
  }
}
`;

export default function HomePage(props) {
  const width = useWindowWidth();
  const { homePageSettings, serviceNodes, title } = props;
  return (
    <Layout>
      <SEO
        title={title}
        description={homePageSettings.description}
        keywords={homePageSettings.keywords}
      />
      <main id="main" className="homePage site-main">
        <div className="fullWidth" style={{ overflow: "hidden" }}>
          <ParallaxContainer images={homePageSettings.parallaxImages} />
          <div id="homeBanner">
            <span>{homePageSettings.title}</span>
          </div>
        </div>
        <div className="wp1220">
          <p className="center">{homePageSettings.description}</p>
          <ul id="homePageServices">
            {serviceNodes &&
              serviceNodes.map((serviceNode) => {
                const mobileStyles = {
                  top: "auto",
                  bottom: 0,
                };
                return (
                  <li id={serviceNode.title} key={serviceNode.id}>
                    <Link to={"/services/" + serviceNode.slug.current}>
                      <img
                        src={imageUrlFor(buildImageObj(serviceNode.thumbnail))
                          .width(150)
                          .url()}
                        alt={serviceNode.thumbnail.alt}
                      />
                      <span
                        style={width <= 900 ? mobileStyles : null}
                        className="textContent"
                      >
                        <span
                          className="title"
                          style={{ color: serviceNode.themeColour.hex }}
                        >
                          <span id="pic">
                            {serviceNode.title}
                            <span id="innerPic">
                              <svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 31 28"
                                style={{
                                  enableBackground: "new 0 0 31 28",
                                  fill: serviceNode.themeColour.hex,
                                }}
                                xmlSpace="preserve"
                              >
                                <polygon
                                  className="st0"
                                  points="16.5,1.2 15.5,2.2 27,13.6 1.7,13.6 1.7,15 27.1,15 15.5,26.7 16.5,27.6 29.7,14.4 "
                                />
                              </svg>
                            </span>
                          </span>
                        </span>
                        <br></br>
                        <span className="shortDescription">
                          {serviceNode.subtitle}
                        </span>
                      </span>
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </main>
    </Layout>
  );
}
