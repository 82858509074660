import { Router } from "@reach/router";
import React, { useEffect, useState } from "react";
import Page, { pageGROQ } from "../../templates/page";
import Service, { serviceGROQ } from "../../templates/service";
import Product, { productGROQ } from "../../templates/product";
import Blog, { blogGROQ } from "../../templates/blog-post";
import Podcast, { podcastGROQ } from "../../templates/podcast";
import LabsPage, { labsGROQ } from "../../templates/labs";
import config from "../../../../studio/sanity.json";
import CaseStudy from "../../components/caseStudy";
import HomePage, { homePageGROQ } from "../../templates/home-page";
import { filterOutDocsWithoutSlugs } from "../../lib/helpers";
import ProductsAndServices, {
  productsAndServicesPageGROQ,
} from "../../templates/productsAndServices";
import AboutUs, { aboutUsGROQ } from "../../templates/about-us";
import TeamMember from "../../components/Team";
import SingleLab from "../../components/SingleLab";
import CompanyInfo from "../../components/CompanyInfo";
import BlogIndex, { blogIndexGROQ } from "../../templates/blogIndex";
import VisualisationIndex, {
  visualisationIndexGROQ,
} from "../../templates/visualisationIndex";
import PodcastsIndex, { podcastIndexGROQ } from "../../templates/podcastsIndex";
import { siteGROQ } from "../../containers/layout";
import Layout from "../../components/layout";
import NoPreview from "../../components/NoPreview";
import Visualisation, {
  visualisationGROQ,
} from "../../templates/visualisations";

const sanityClient = require("@sanity/client");
const client = sanityClient({
  projectId: config.api.projectId,
  dataset: config.api.dataset,
  useCdn: false, // `false` if you want to ensure fresh data
  withCredentials: true,
});

const PreviewPage = (props) => {
  const { id } = props;
  const [data, setData] = useState();
  const [previewType, setPreviewType] = useState();
  const query = "*[_id == $id]";
  const params = { id };

  useEffect(() => {
    var realQuery;
    client.fetch(query, params).then((response) => {
      setPreviewType(response[0]._type);
      switch (response[0]._type) {
        case "page":
          realQuery = pageGROQ;
          break;
        case "service":
          realQuery = serviceGROQ;
          break;
        case "product":
          realQuery = productGROQ;
          break;
        case "post":
          realQuery = blogGROQ;
          break;
        case "podcast":
          realQuery = podcastGROQ;
          break;
        case "labsSettings":
          realQuery = labsGROQ;
          break;
        case "aboutUsSettings":
          realQuery = aboutUsGROQ;
          break;
        case "homePageSettings":
          realQuery = homePageGROQ;
          break;
        case "blogSettings":
          realQuery = blogIndexGROQ;
          break;
        case "podcastSettings":
          realQuery = podcastIndexGROQ;
          break;
        case "productsServicesSettings":
          realQuery = productsAndServicesPageGROQ;
          break;
        case "siteSettings":
          realQuery = siteGROQ;
          break;
        case "visualisationSettings":
          realQuery = visualisationIndexGROQ;
          break;
        case "visualisation":
          realQuery = visualisationGROQ;
          break;
        default:
          realQuery = "*[_id == $id]";
      }
      client.fetch(realQuery, params).then((realResponse) => {
        setData(realResponse);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return <div>Loading...</div>;
  if (!previewType) return <NoPreview />;

  switch (previewType) {
    case "page":
      return <Page data={{ page: data[0] }} />;
    case "service":
      return <Service data={{ service: data[0] }} />;
    case "product":
      return <Product data={{ product: data[0] }} />;
    case "post":
      return (
        <Blog
          data={{
            sanityBlogSettings: data.find((el) => el._type === "blogSettings"),
            post: data.find((el) => el._type === "post"),
          }}
        />
      );
    case "podcast":
      return (
        <Podcast
          data={{
            sanityPodcastSettings: data.find(
              (el) => el._type === "podcastSettings"
            ),
            podcast: data.find((el) => el._type === "podcast"),
          }}
        />
      );
    case "teamMember":
      const member = data[0];
      return (
        <div style={{ margin: "40px 0" }} id="aboutUs">
          <div className="wp1220">
            <TeamMember teamMember={member} />
          </div>
        </div>
      );
    case "caseStudy":
      const serviceBody = data[0]._rawBody
        ? data[0]._rawBody
        : data[0].body
        ? data[0].body
        : [];
      return (
        <div style={{ margin: "40px 0" }}>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Preview of content/layout, correct colour is not represented
          </h1>
          <div id="services" style={{ backgroundColor: "rgb(191, 31, 36)" }}>
            <CaseStudy data={data} serviceBody={serviceBody} />
          </div>
        </div>
      );
    case "homePageSettings":
      const homePageSettings = data.find(
        (el) => el._type === "homePageSettings"
      );
      const serviceNodes = homePageSettings.services
        ? homePageSettings.services.filter(filterOutDocsWithoutSlugs)
        : [];
      return (
        <HomePage
          homePageSettings={homePageSettings}
          serviceNodes={serviceNodes}
        />
      );
    case "productsServicesSettings":
      const sanityProductsServicesSettings = data[0];
      const psServiceNodes = sanityProductsServicesSettings.services
        ? sanityProductsServicesSettings.services.filter(
            filterOutDocsWithoutSlugs
          )
        : [];
      const psProductNodes = sanityProductsServicesSettings.products
        ? sanityProductsServicesSettings.products.filter(
            filterOutDocsWithoutSlugs
          )
        : [];
      return (
        <ProductsAndServices
          sanityProductsServicesSettings={sanityProductsServicesSettings}
          serviceNodes={psServiceNodes}
          productNodes={psProductNodes}
        />
      );
    case "labsSettings":
      const sanityLabsSettings = data[0];
      return <LabsPage sanityLabsSettings={sanityLabsSettings} />;
    case "lab":
      return (
        <div style={{ margin: "40px 0" }} id="labs">
          <div className="wp1220">
            <section id="labsTab">
              <ul>
                <SingleLab lab={data[0]} />
              </ul>
            </section>
          </div>
        </div>
      );
    case "companyInfo":
      return (
        <div style={{ margin: "40px 0" }} id="aboutUs">
          <div className="wp1220">
            <CompanyInfo sanityCompanyInfo={data[0]} />
          </div>
        </div>
      );
    case "aboutUsSettings":
      const sanityAboutUsSettings = data.find(
        (el) => el._type === "aboutUsSettings"
      );
      const sanityCompanyInfo = data.find((el) => el._type === "companyInfo");
      return (
        <AboutUs
          sanityAboutUsSettings={sanityAboutUsSettings}
          sanityCompanyInfo={sanityCompanyInfo}
        />
      );
    case "blogSettings":
      const blogSettingsElement = data.find(
        (el) => el._type === "blogSettings"
      );
      return (
        <BlogIndex
          data={{
            sanityBlogSettings: blogSettingsElement,
            posts: blogSettingsElement.posts.slice(0, 12),
            archivePosts: blogSettingsElement.posts,
          }}
        />
      );
    case "podcastSettings":
      const podcastSettingsElement = data.find(
        (el) => el._type === "podcastSettings"
      );
      return (
        <PodcastsIndex
          data={{
            sanityPodcastSettings: podcastSettingsElement,
            podcasts: podcastSettingsElement.podcasts.slice(0, 12),
            archivePodcasts: podcastSettingsElement.podcasts,
          }}
        />
      );
    case "siteSettings":
      const siteSettingsElement = data.find(
        (el) => el._type === "siteSettings"
      );
      return (
        <Layout
          siteTitle={siteSettingsElement.title}
          siteLogo={siteSettingsElement.logo}
          headerNavigation={siteSettingsElement.headerNavigation}
          footerNavigation={siteSettingsElement.footerNavigation}
          socialHeader={siteSettingsElement.socialHeader}
          socialLinks={siteSettingsElement.socialLinks}
          footerText={siteSettingsElement.footerText}
        >
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Page content will appear here in production site
            <p />
          </div>
        </Layout>
      );
    case "visualisation":
      return (
        <Visualisation
          data={{
            sanityVisualisationSettings: data.find(
              (el) => el._type === "visualisationSettings"
            ),
            visualisation: data.find((el) => el._type === "visualisation"),
          }}
        />
      );
    case "visualisationSettings":
      const visualisationSettingsElement = data.find(
        (el) => el._type === "visualisationSettings"
      );
      return (
        <VisualisationIndex
          data={{
            sanityVisualisationSettings: visualisationSettingsElement,
            visualisation: visualisationSettingsElement.visualisation.slice(
              0,
              12
            ),
            archivePosts: visualisationSettingsElement.visualisation,
            visualisationCategories:
              visualisationSettingsElement.visualisationCategories,
          }}
        />
      );
    default:
      return <NoPreview />;
  }
};

const Preview = () => (
  <Router>
    <PreviewPage path="/preview/:id" />
  </Router>
);

export default Preview;
