import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import BlockContent from "../block-content";
import Container from "../container";
import RoleList from "../role-list";
import { Link } from "gatsby";
import styles from "./blog-post.module.css";

function BlogPost(props) {
  const { _rawBody, body, author, categories, publishedAt } = props;
  const blogBody = _rawBody ? _rawBody : body ? body : [];
  const visualisations = props.visualisations ? props.visualisations : [];

  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {<BlockContent blocks={blogBody} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Do YYYY")}
              </div>
            )}
            {author && <RoleList item={author} title="Author" />}
            {categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>
                  <strong>Categories</strong>
                </h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {visualisations.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>
                  <strong>Visualisations</strong>
                </h3>
                <ul>
                  {visualisations.map((visualisation) => (
                    <li key={visualisation._id}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/visualisations/${visualisation.slug.current}`}
                      >
                        {visualisation.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
