import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import styles from "./role-list.module.css";

function RoleList({ item, title }) {
  return (
    <div className={styles.root}>
      {item && (
        <>
          <strong>{title}</strong>
          <div>
            {item.mainImage && item.mainImage.asset && (
              <img
                src={imageUrlFor(buildImageObj(item.mainImage))
                  // .width(100)
                  // .height(100)
                  //.fit('crop')
                  .url()}
                alt={item.mainImage.alt}
              />
            )}
          </div>
          <div>{item.title || <em>Missing</em>}</div>
        </>
      )}
    </div>
  );
}

export default RoleList;
