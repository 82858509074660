import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Podcast from "../components/podcast/podcast";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import ParallaxContainer from "../components/parallax-container";

export const podcastGROQ = `
*[_id == 'podcastSettings' || _id == $id] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  widgetColour,
  widgetAutoPlay,
  widgetHideRelated,
  widgetShowComments,
  widgetShowReposts,
  widgetShowTeaser,
  widgetBuying,
  widgetSharing,
  widgetDownload,
  widgetShowArtwork,
  widgetShowPlayCount,
  widgetShowUser,
  widgetStartTrack,
  widgetSingleActive,
  publishedAt,
  author->{
    title,
    mainImage {
      asset->
    }
  },
	categories[]->,
	visualisations[]->,
	mainImage {
    alt,
    asset->
  },
	slug,
	body
}`;

export const query = graphql`
  query PodcastTemplateQuery($id: String!) {
    sanityPodcastSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      widgetColour {
        hex
      }
      widgetAutoPlay
      widgetHideRelated
      widgetShowComments
      widgetShowReposts
      widgetShowTeaser
      widgetBuying
      widgetSharing
      widgetDownload
      widgetShowArtwork
      widgetShowPlayCount
      widgetShowUser
      widgetStartTrack
      widgetSingleActive
    }

    podcast: sanityPodcast(id: { eq: $id }) {
      id
      publishedAt
      author {
        title
        mainImage {
          asset {
            _id
            url
          }
          alt
        }
      }
      categories {
        _id
        title
      }
      visualisations {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      _rawBody
    }
  }
`;

const PodcastTemplate = (props) => {
  const { data, errors } = props;
  const sanityPodcastSettings = (data || {}).sanityPodcastSettings;
  const podcast = data && data.podcast;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {podcast && <SEO title={podcast.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div className="fullWidth">
            <ParallaxContainer images={sanityPodcastSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{podcast.title}</span>
            </div>
          </div>
          <div className="wp1220">
            {podcast && (
              <Podcast
                podcast={podcast}
                podcastSettings={sanityPodcastSettings}
              />
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PodcastTemplate;
