import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import DarkLayout from "../containers/darkLayout";
import Layout from "../containers/layout";
import Visualisation from "../components/visualisations/visualisation";
import "../components/visualisations/visualisation.css";

export const visualisationGROQ = `
*[_id == 'visualisationSettings' || _id == $id] {
  _id,
  _type,
  title,
  description,
  isDark,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  publishedAt,
  author->{
    title,
    mainImage {
      asset->
    }
  },
  categories[]->,
  scripts,
	mainImage {
    alt,
    asset->
  },
  slug,
  width,
  height,
  topText,
  bottomText,
  sideText
}`;

export const query = graphql`
  query VisualisationTemplateQuery($id: String!) {
    sanityVisualisationSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
    }

    visualisation: sanityVisualisation(id: { eq: $id }) {
      id
      publishedAt
      title
      isDark
      slug {
        current
      }
      scripts {
        scriptSrc
        type
      }
      author {
        title
        mainImage {
          asset {
            _id
          }
          alt
        }
      }
      categories {
        _id
        title
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      width
      height
      _rawTopText
      _rawBottomText
      _rawSideText
    }
  }
`;

const InnerVisualisation = (props) => {
  const { data, errors } = props;
  const visualisation = data && data.visualisation;
  return (
    <>
    {errors && <SEO title="GraphQL Error" />}
    {visualisation && <SEO title={visualisation.title || "Untitled"} />}
    {errors && (
      <Container>
        <GraphQLErrorList errors={errors} />
      </Container>
    )}
    <main id="main" className="site-main" role="main">
      <div className="fullWidthNoBanner">
        <span className={visualisation.isDark ? "darkVisualisationHeader" : "visualisationHeader"}>{visualisation.title}</span>
      </div>

      <div>
        <div className="wp1220">
          {visualisation && <Visualisation {...visualisation} />}
        </div>
      </div>
    </main>
    </>
  )
}

const VisualisationTemplate = (props) => {
  const { data } = props;
  const visualisation = data && data.visualisation;

  if (visualisation.isDark) {
    return (
      <DarkLayout>
        <InnerVisualisation {...props} />
      </DarkLayout>
    );
  }


  return (
    <Layout>
        <InnerVisualisation {...props} />
    </Layout>
  );
};

export default VisualisationTemplate;
