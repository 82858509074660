import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes } from "../lib/helpers";
import ParallaxContainer from "../components/parallax-container";
import Pagination from "../components/Pagination";
import Archive from "../components/blog/Archive";
import VisualisationPreviewGrid from "../components/visualisations/visualisation-preview-grid";
import "../components/visualisations/visualisation.css";

const VisualisationIndex = (props) => {
  const { data, errors } = props;
  const currentCategory =
    (props.pageContext && props.pageContext.category) || null;
  const [filteredData, setFilteredData] = useState([]);
  const currentPage = props.pageContext && props.pageContext.currentPage;
  const numPages = props.pageContext && props.pageContext.numPages;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentCategory
    ? currentPage - 1 === 1
      ? `/visualisations/${currentCategory}`
      : `/visualisations/${currentCategory}/${(currentPage - 1).toString()}`
    : currentPage - 1 === 1
    ? "/visualisations"
    : `/visualisations/${(currentPage - 1).toString()}`;
  const nextPage = currentCategory
    ? `/visualisations/${currentCategory}/${(currentPage + 1).toString()}`
    : `/visualisations/${(currentPage + 1).toString()}`;

  const sanityVisualisationSettings = (data || {}).sanityVisualisationSettings;
  const visualisationCategories =
    data &&
    data.visualisationCategories &&
    mapEdgesToNodes(data.visualisationCategories);
  const visualisationNodes =
    data && data.visualisation && mapEdgesToNodes(data.visualisation);

  const archivePosts =
    data && data.archivePosts && mapEdgesToNodes(data.archivePosts);

  useEffect(() => {
    currentCategory
      ? setFilteredData(
          visualisationNodes.filter((visual) =>
            visual.categories.some(
              (category) => category.title === currentCategory
            )
          )
        )
      : setFilteredData(visualisationNodes);
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO
        title={sanityVisualisationSettings.title}
        description={sanityVisualisationSettings.description}
        keywords={sanityVisualisationSettings.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div style={{ overflow: "hidden" }} className="fullWidth">
            <ParallaxContainer
              images={sanityVisualisationSettings.parallaxImages}
            />
            <div id="homeBanner">
              <span>{sanityVisualisationSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <p className="center">{sanityVisualisationSettings.description}</p>
            {currentCategory && (
              <div className="current-category-wrapper">
                <p className="category-label">CURRENT CATEGORY:</p>
                <p className="current-category">{currentCategory}</p>
              </div>
            )}

            <div className="index-wrapper">
              <div className="index-main-content">
                {visualisationNodes && visualisationNodes.length > 0 && (
                  <VisualisationPreviewGrid nodes={filteredData} />
                )}
              </div>
              <div className="index-sidebar">
                {visualisationCategories && (
                  <h1 className="index-sidebar-header">Categories</h1>
                )}
                {visualisationCategories &&
                  visualisationCategories.map((category, index) => (
                    <Link
                      className="visual-category-button"
                      to={`/visualisations/${category.title}`}
                      key={index}
                    >
                      {category.title}
                    </Link>
                  ))}
                {currentCategory && (
                  <Link
                    className="visual-category--reset-button"
                    to="/visualisations"
                  >
                    CLEAR FILTER
                  </Link>
                )}
                <Archive title="Visuals Archive" archiveData={archivePosts} />
              </div>
            </div>
            {currentPage && (
              <Pagination
                isFirst={isFirst}
                prevPage={prevPage}
                currentPage={currentPage}
                numPages={numPages}
                nextPage={nextPage}
                isLast={isLast}
              />
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default VisualisationIndex;

export const visualisationIndexGROQ = `
*[ _id == $id ] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  	"visualisationCategories": *[_type == 'visualisationCategory'] {
    title,
    _type
  },
	slug,
  body,
  "visualisation": *[_type=='visualisation' && defined(slug.current)] | order(publishedAt desc) {
    id,
    publishedAt,
    mainImage {asset->},
		title,
		body,
		slug
  }
}
`;

export const query = graphql`
  query VisualisationPageQuery($skip: Int!, $limit: Int!) {
    sanityVisualisationSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
    }
    visualisationCategories: allSanityVisualisationCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    archivePosts: allSanityVisualisation(
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          categories {
            id
            title
          }
          slug {
            current
          }
        }
      }
    }

    visualisation: allSanityVisualisation(
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            _type
            asset {
              _id
            }
          }
          slug {
            _type
            current
          }
          title
          categories {
            id
            title
          }
          sideText {
            _key
            _type
            style
          }
          topText {
            style
            _type
            _key
          }
          author {
            _type
          }
        }
      }
    }
  }
`;
