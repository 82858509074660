import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import BlogPost from "../components/blog/blog-post";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import ParallaxContainer from "../components/parallax-container";

export const blogGROQ = `
*[_id == 'blogSettings' || _id == $id] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  publishedAt,
  author->{
    title,
    mainImage {
      asset->
    }
  },
	categories[]->,
	visualisations[]->,
	mainImage {
    alt,
    asset->
  },
	slug,
	body
}`;

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    sanityBlogSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
    }

    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      author {
        title
        mainImage {
          asset {
            _id
            url
          }
          alt
        }
      }
      categories {
        _id
        title
      }
      visualisations {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      _rawBody
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const sanityBlogSettings = (data || {}).sanityBlogSettings;
  const post = data && data.post;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && <SEO title={post.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div className="fullWidth">
            <ParallaxContainer images={sanityBlogSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{post.title}</span>
            </div>
          </div>
          <div className="wp1220">{post && <BlogPost {...post} />}</div>
        </div>
      </main>
    </Layout>
  );
};

export default BlogPostTemplate;
