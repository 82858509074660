import React from "react";
import BlockContent from "./block-content";

import ParallaxContainer from "./parallax-container";

function Product(props) {
  const {
    _rawBody,
    _rawFootnote,
    body,
    footnote,
    title,
    subtitle,
    parallaxImages,
    parallaxColour,
    themeColour,
    features,
  } = props;
  const productBody = _rawBody ? _rawBody : body ? body : [];
  const productFootnote = _rawFootnote
    ? _rawFootnote
    : footnote
    ? footnote
    : [];
  return (
    <article>
      <header className="product-header">
        <div className="product-content wpFullWidth" id="productNew">
          <div id="titleWithImage" style={{ background: themeColour.hex }}>
            <ParallaxContainer
              style={{ backgroundColor: parallaxColour.hex }}
              images={parallaxImages}
            />
          </div>
          <h1>{title}</h1>
          <div className="wp1220">
            <div
              className="col1_2 shortDescription"
              style={{ color: themeColour.hex }}
            >
              {subtitle}
            </div>
            <div className="content">
              {<BlockContent blocks={productBody} />}
              <div id="greyContent">
                {<BlockContent blocks={productFootnote} />}
              </div>
              <div className="stages">
                <div className="product-stages-list">
                  {features &&
                    features.map((feature, i) => (
                      <div key={i} className="single-stage">
                        <span
                          style={{ color: themeColour.hex }}
                          className="stage-order"
                        >
                          {i + 1}
                        </span>
                        <span
                          className="stage-title"
                          style={{ color: themeColour.hex }}
                        >
                          {feature.title}
                        </span>
                        <p className="stage-content">{feature.body}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </article>
  );
}

export default Product;
