import React from 'react'

function SoundCloudPodcast (props) {
  const {settings, node} = props;
  const {
    widgetColour,
    widgetAutoPlay,
    widgetHideRelated,
    widgetShowComments,
    widgetShowReposts,
    widgetShowTeaser,
    widgetBuying,
    widgetSharing,
    widgetDownload,
    widgetShowArtwork,
    widgetShowPlayCount,
    widgetShowUser,
    widgetStartTrack,
    widgetSingleActive
  } = settings;
  const soundcloudURL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + node.soundcloudID
    + "&amp;color=%23" + widgetColour.hex.substring(1)
    + "&amp;auto_play=" + (widgetAutoPlay || false)
    + "&amp;hide_related=" + (widgetHideRelated || false)
    + "&amp;show_comments=" + (widgetShowComments || false)
    + "&amp;show_reposts=" + (widgetShowReposts || false)
    + "&amp;show_teaser=" + (widgetShowTeaser || false)
    + "&amp;buying=" + (widgetBuying || false)
    + "&amp;sharing=" + (widgetSharing || false)
    + "&amp;download=" + (widgetDownload || false)
    + "&amp;show_artwork=" + (widgetShowArtwork || false)
    + "&amp;show_playcount=" + (widgetShowPlayCount || false)
    + "&amp;show_user=" + (widgetShowUser || false)
    + "&amp;start_track=" + (widgetStartTrack || 0)
    + "&amp;single_active=" + (widgetSingleActive|| false)
  ;
  return (
    <iframe
      width="100%"
      height="166"
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={soundcloudURL}
      title={node.soundcloudID}
    />
  )
}

export default SoundCloudPodcast
