import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Product from "../components/product";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const productGROQ = `
*[_id == $id] {
  _id,
  _type,
  thumbnail,
  title,
  subtitle,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
  themeColour,
  slug,
  body,
  footnote,
  features
}`;

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      thumbnail {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      subtitle
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      themeColour {
        hex
      }
      slug {
        current
      }
      _rawBody
      _rawFootnote
      features {
        title
        body
      }
    }
  }
`;

const ProductTemplate = (props) => {
  const { data, errors } = props;
  const product = data && data.product;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {product && <SEO title={product.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {product && <Product {...product} />}
    </Layout>
  );
};

export default ProductTemplate;
